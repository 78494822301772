<template>
  <!-- #region template -->
  <b-container fluid class="vh-100 overflow-hidden bg-white">
    <b-row class="h-100">
      <b-col cols="12" xl="3" class="p-5 d-flex flex-column justify-content-between">
        <div class="text-center">
          <cLogo />
        </div>
        <div>
          <div class="text-center">
            <h2 class="font-weight-bold mb-3">{{ $t('vNotFound.title') }}</h2>
            <p class="px-5 mb-4">{{ $t('vNotFound.description') }}</p>
            <b-button to="/?dashboard=TlJMdJXCYaElTeSlGl86" class="px-5" type="submit" variant="dark">{{ $t('vNotFound.button') }}</b-button>
          </div>
        </div>
        <div></div>
      </b-col>
      <b-col cols="9" class="p-0 d-none d-xl-block" style="overflow: hidden">
        <video class="bg-black w-100 h-100" style="transform: scale(1.2)" autoplay>
          <source src="@/assets/S22_reveal_02.mp4" type="video/mp4" />
        </video>
      </b-col>
    </b-row>
  </b-container>
  <!-- #endregion -->
</template>

<script>
// #region script
// #region import
import cLogo from '@/components/cLogo.vue'
// #endregion

// #region export
export default {
  name: 'vNotFound',
  components: {
    cLogo,
  },
}
// #endregion
// #endregion
</script>
